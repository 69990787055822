import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import { createContext, ReactNode, useCallback, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
// routes
import { PATH_AUTH } from '../routes/paths';
// @types
import { ActionMap, AuthState, AuthUser, AWSCognitoContextType } from '../@types/authentication';
//
import Amplify, { Auth, Hub } from 'aws-amplify';
import { PortalUserEntity } from 'src/api/useUserApi';
import { loginPathNameLocalStrageKey } from 'src/components/authentication/login/LoginForm';
import { getBearerToken } from 'src/utils/getBearerToken';
import { amplifyConfig, portalApi } from '../config';

export const UserPool = new CognitoUserPool({
  UserPoolId: amplifyConfig.aws_user_pools_id || '',
  ClientId: amplifyConfig.aws_user_pools_web_client_id || '',
});

Amplify.configure({
  Auth: {
    region: 'ap-northeast-1',
    userPoolId: amplifyConfig.aws_user_pools_id,
    userPoolWebClientId: amplifyConfig.aws_user_pools_web_client_id,
    oauth: {
      domain: amplifyConfig.cognitoDomain,
      scope: ['openid', 'email', 'profile'],
      // TODO:ここを環境変数等で指定できるようにする必要あり
      redirectSignIn: amplifyConfig.redirect_sign_in,
      redirectSignOut: amplifyConfig.redirect_sign_out,
      responseType: 'code',
      // options: {},
      // urlOpener: (url: string, redirectUrl: string) => Promise < any >,
    },
  },
});

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  method: 'cognito',
};

enum Types {
  auth = 'AUTHENTICATE',
  logout = 'LOGOUT',
}

type AwsAuthPayload = {
  [Types.auth]: {
    isAuthenticated: boolean;
    user: AuthUser;
    method: string;
  };
  [Types.logout]: undefined;
};

type AwsActions = ActionMap<AwsAuthPayload>[keyof ActionMap<AwsAuthPayload>];

const reducer = (state: AuthState, action: AwsActions) => {
  if (action.type === 'AUTHENTICATE') {
    const { isAuthenticated, user, method } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      method,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

const AuthContext = createContext<AWSCognitoContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getUserAttributes = useCallback(
    (currentUser: CognitoUser): Record<string, any> =>
      new Promise((resolve, reject) => {
        currentUser.getUserAttributes((err, attributes) => {
          if (err) {
            reject(err);
          } else {
            const results: Record<string, any> = {};

            attributes?.forEach((attribute) => {
              results[attribute.Name] = attribute.Value;
            });
            resolve(results);
          }
        });
      }),
    [],
  );

  const getMethod = useCallback(async (email: string) => {
    // AuthProviderが呼び出されるタイミングではenqueueSnackbarが使えないため axios.getで取得を行う
    try {
      // リクエスト
      const token = await getBearerToken();
      const result = await axios.get<PortalUserEntity[]>(portalApi + '/user/account', {
        headers: { Authorization: token },
        params: { filter: { where: { emailAddress: email }, take: 1 } },
      });

      // Resourceを取得 ACCOUNT, COGNITO, PLUTO
      if (result && result.data.length > 0) {
        return result.data[0].resource;
      }
    } catch (error) {
      // RootErrorBoundaryが反応するのはRouter入ってからなのでここでErrorをthrowしても反応しない
      // この後COGNITOが返ることでuseAuthで403となる
    }
    return 'COGNITO';
  }, []);

  const getSession = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser();

        if (user) {
          user.getSession(async (err: Error | null, session: CognitoUserSession | null) => {
            if (err) {
              reject(err);
            } else {
              const attributes = await getUserAttributes(user);
              const token = session?.getIdToken().getJwtToken();

              const payload = session?.getIdToken().payload || {};
              Object.keys(payload).forEach((key) => {
                attributes[key] = payload[key];
              });

              // use the token or Bearer depend on the wait BE handle, by default amplify API only need to token.
              // axios.defaults.headers.common.Authorization = 'Bearer ' + token;
              axios.interceptors.request.use(async (request) => {
                const token = await new Promise((resolve2) => {
                  user.getSession(async (err: Error | null, session: CognitoUserSession | null) => {
                    if (err) {
                      alert('セッションの有効時間が切れましたので再ログインしてください。(The session has expired, so please log in again.)'); //遭遇頻度が低くsnackbarが使用できないためalertにて表示
                    } else {
                      const token = session?.getIdToken().getJwtToken();
                      resolve2(token);
                    }
                  });
                });
                request.headers.Authorization = 'Bearer ' + token;
                return request;
              });

              dispatch({
                type: Types.auth,
                payload: { isAuthenticated: true, user: attributes, method: await getMethod(attributes.email) },
              });
              resolve({
                user,
                session,
                headers: { Authorization: token },
              });
            }
          });
        } else {
          dispatch({
            type: Types.auth,
            payload: {
              isAuthenticated: false,
              user: null,
              method: 'COGNITO',
            },
          });
        }
      }),
    [getMethod, getUserAttributes],
  );

  const initial = useCallback(async () => {
    try {
      await getSession();
    } catch {
      dispatch({
        type: Types.auth,
        payload: {
          isAuthenticated: false,
          user: null,
          method: 'COGNITO',
        },
      });
    }
  }, [getSession]);

  const initialForAd = () => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          break;
        case 'cognitoHostedUI':
          (async () => {})();
          break;
        case 'signOut':
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          break;
      }
    });

    // 毎回実行される
    getUser().then(async (userData) => {
      await loginFromAzure(userData!);
    });

    return () => {
      Hub.remove('auth', () => Auth.signOut().catch(() => {}));
    };
  };

  useEffect(() => {
    initialForAd();
    initial();
  }, [initial]); //eslint-disable-line

  function getUser() {
    return Auth.currentSession()
      .then((userData) => userData)
      .catch(() => {});
  }

  // We make sure to handle the user update here, but return the resolve value in order for our components to be
  // able to chain additional `.then()` logic. Additionally, we `.catch` the error and "enhance it" by providing
  // a message that our React components can use.
  const login = useCallback(
    async (email, password) => {
      //TODO: いる？いらない？
      // const result = await Auth.signIn(email, password);

      return await new Promise((resolve, reject) => {
        const user = new CognitoUser({
          Username: email,
          Pool: UserPool,
        });

        const authDetails = new AuthenticationDetails({
          Username: email,
          Password: password,
        });

        user.authenticateUser(authDetails, {
          onSuccess: (data) => {
            getSession();
            resolve(data);
          },
          onFailure: (err) => {
            reject({
              isNewPasswordRequired: false,
              data: err,
            });
          },
          newPasswordRequired: () => {
            reject({
              isNewPasswordRequired: true,
              data: user,
            });
          },
        });
      });
    },
    [getSession],
  );

  // same thing here
  const logout = async () => {
    const user = UserPool.getCurrentUser();

    if (user) {
      // TODO: これだと失敗する.
      //await Auth.signOut({ global: true });
      // await Auth.signOut({ global: false });

      // TODO: これでもAzureのサインアウトはできない。
      // user.globalSignOut({
      //   onSuccess: (msg: string) => {},
      //   onFailure: (err: Error) => {},
      // });
      await new Promise((resolve) => {
        user.signOut(() => {
          dispatch({ type: Types.logout });
          resolve(void 0);
        });
      });
    } else {
      dispatch({ type: Types.logout });
    }

    //ここでログアウト処理
  };

  const updatePassword = async (user: CognitoUser, password: string) => {
    return await new Promise((resolve, reject) => {
      user.completeNewPasswordChallenge(
        password,
        {},
        {
          onSuccess: () => {
            getSession();
            resolve(true);
          },
          onFailure: () => {
            reject(false);
          },
        },
      );
    });
  };

  const register = (email: string, password: string, firstName: string, lastName: string) =>
    new Promise((resolve, reject) => {
      UserPool.signUp(
        email,
        password,
        [new CognitoUserAttribute({ Name: 'email', Value: email }), new CognitoUserAttribute({ Name: 'name', Value: `${firstName} ${lastName}` })],
        [],
        async (err) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(undefined);
          window.location.href = PATH_AUTH.login;
        },
      );
    });

  const loginFromAzure = (session: CognitoUserSession) =>
    new Promise(async (resolve, reject) => {
      if (!session) {
        dispatch({ type: Types.logout });
      } else {
        // const token = session?.getIdToken().getJwtToken();
        // use the token or Bearer depend on the wait BE handle, by default amplify API only need to token.
        // axios.defaults.headers.common.Authorization = token;
        // axios.defaults.headers.common.Authorization = 'Bearer ' + token;
        const user = UserPool.getCurrentUser()!;
        axios.interceptors.request.use(async (request) => {
          const token = await new Promise((resolve2) => {
            user.getSession(async (err: Error | null, session: CognitoUserSession | null) => {
              if (err) {
                alert('セッションの有効時間が切れましたので再ログインしてください。(The session has expired, so please log in again.)'); //遭遇頻度が低くsnackbarが使用できないためalertにて表示
              } else {
                const token = session?.getIdToken().getJwtToken();
                resolve2(token);
              }
            });
          });
          request.headers.Authorization = 'Bearer ' + token;
          return request;
        });

        // ここでPayloadの値を設定!!!!
        const payload = session?.getIdToken().payload || {};
        let attributes: any = {};

        Object.keys(payload).forEach((key) => {
          // ('🍌', key, ':', payload![key]);
          attributes[key] = payload[key];
        });

        dispatch({
          type: Types.auth,
          payload: {
            isAuthenticated: true,
            user: attributes,
            method: await getMethod(attributes.email),
          },
        });

        const loginPath: string | null = localStorage.getItem(loginPathNameLocalStrageKey);
        if (loginPath) {
          localStorage.removeItem(loginPathNameLocalStrageKey);
          //指定されているURLにリダイレクト
          window.location.href = loginPath!;
        }
      }
    });

  const resetPassword = async (email: string) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    await new Promise((resolve, reject) => {
      user.forgotPassword({
        onSuccess: (data) => {
          // ここには入らない想定
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        },
        inputVerificationCode: (data) => {
          resolve(data);
        },
      });
    });
  };

  const newPassword = async (email: string, code: string, password: string) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    await new Promise((resolve, reject) => {
      user.confirmPassword(code, password, {
        onSuccess() {
          resolve(() => {});
        },
        onFailure(err) {
          reject(err);
        },
      });
    });
  };

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        user: {
          displayName: state?.user?.['cognito:username'] || 'NO NAME',
          // TODO: rolesにしたい・・・
          role: JSON.parse(state?.user?.['roles'] || '[]'),
          subCompaniesArray: JSON.parse(state?.user?.['subCompanies'] || '[]'),
          subDepartmentsArray: JSON.parse(state?.user?.['subDepartments'] || '[]'),
          subDivisionsArray: JSON.parse(state?.user?.['subDivisions'] || '[]'),
          ...state.user,
        },
        login,
        register,
        logout,
        updateProfile,
        resetPassword,
        loginFromAzure,
        newPassword,
        updatePassword,
      }}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
