import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack5';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';

export const useBearerToken = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const auth = useAuth();

  const getBearerToken = useCallback(async () => {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      return `Bearer ${token}`;
    } catch (error) {
      enqueueSnackbar(t('account.failedGetToken'), {
        variant: 'warning',
        preventDuplicate: true,
      });
      auth.logout();
    }
  }, [enqueueSnackbar, t, auth]);

  return { getBearerToken };
};
