import { useTranslation } from 'react-i18next';
import { SeriesImportResult } from 'src/api/useEnqueteAnswerInputApi';
import { ErrorMessageEntity } from 'src/api/useEnqueteStatusPageApi';
import * as importExValidate from '../../hooks/useImportExcelValidate';

// 系列別　Excelインポートエラーコード
export const SERIES_ERROR_CODE = {
  READ_ONLY_AMOUNT: 0,
  READ_ONLY_BALANCE: 1,
  NOT_SERIES_CUSTOMER: 2,
  NOT_EXIST_CUSTOMER: 3,
} as const;
export type SeriesErrorCode = typeof SERIES_ERROR_CODE[keyof typeof SERIES_ERROR_CODE];

//ExcelバリデーションHooks
const useImportExcelValidate = () => {
  const importHooks = importExValidate.default();
  const { t } = useTranslation();

  //配列でエラーコード毎のメッセージを取得
  const getErrorMessageList = (errorObjList: ErrorMessageEntity[] | [] | string) => {
    let errorList = [];

    //文字列だった場合の処理
    if (typeof errorObjList === 'string') {
      errorList.push(errorObjList);
      return errorList;
    }
    // memo: defaultのgetErrorMessageListと動きを変えたいものをcaseを追加する
    switch (errorObjList[0].code) {
      // memo: defaultのgetErrorMessageListのメッセージではないメッセージを返したい場合にcaseを追加する
      case importExValidate.ERROR_CODE.EMPTY_RECORDS:
        errorList.push(t('message.emptyRespondent'));
        break;
      // memo: enquete-createではORGANIZATION_MISMATCHがある場合はORGANIZATION_MISMATCHのみを表示したいため、caseを追加
      case importExValidate.ERROR_CODE.ORGANIZATION_MISMATCH:
        errorList.push(t('message.organizationMissMatch'));
        break;
      // memo:
      //   系列別回答インポートでのみ発生するエラーメッセージであるため、defaultルートのgetErrorMessageListではなくこちらに記載
      //   TODO:系列別固有のエラーはSERIES_ERROR_CODEで定義されているのでそちらに移動すべきコード
      case importExValidate.ERROR_CODE.TARGET_DATE_MISMATCH:
        const description = errorObjList[0].description.split(',');
        errorList.push(t('errorReport.errorMessage.targetDateMissMatch', { targetDate: description[0], importTargetDate: description[1] }));
        break;
      default:
        errorList = importHooks.getErrorMessageList(errorObjList);
        break;
    }
    return errorList;
  };

  //配列でエラーコード毎のメッセージを取得(系列別)
  const getSeriesErrorMessageList = (errorObjList: SeriesImportResult[] | []) => {
    return errorObjList.map((v) => getSeriesErrorMessage(v));
  };

  //エラーコード毎のメッセージを取得(系列別)
  const getSeriesErrorMessage = (errorObj: SeriesImportResult) => {
    let errorMessage = '';

    switch (errorObj.code) {
      // 過去データ登録済みのレコードの金額が変更されている場合
      case SERIES_ERROR_CODE.READ_ONLY_AMOUNT:
        errorMessage = t('enqueteAnswerInput.message.updatePreviousDataError', { row: errorObj.detail });
        break;

      // 親会社がトヨタ自動車/トヨタ車体/日野自動車/ダイハツ工業以外で債権債務の入力がある場合のコード
      case SERIES_ERROR_CODE.READ_ONLY_BALANCE:
        errorMessage = t('enqueteAnswerInput.message.importBalanceError', { row: errorObj.detail });
        break;

      // 取引先の親会社が主要22社でない場合のコード
      case SERIES_ERROR_CODE.NOT_SERIES_CUSTOMER:
        errorMessage = t('enqueteAnswerInput.message.importOutsideSeriesError', { row: errorObj.detail });
        break;

      // 回答済みの取引先の回答レコードがインポートファイルにない場合のコード
      case SERIES_ERROR_CODE.NOT_EXIST_CUSTOMER:
        errorMessage = t('enqueteAnswerInput.message.notExistCustomerError', { code: errorObj.detail });
        break;

      default:
    }

    return errorMessage;
  };

  return { getErrorMessageList, getSeriesErrorMessageList, getSeriesErrorMessage };
};

export default useImportExcelValidate;
