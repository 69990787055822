import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import type { UserMenuOptions } from './@types/layouts';
import { BonsaiRole, Role } from './@types/role';
import { PATH_DASHBOARD, PATH_GENERAL } from './routes/paths';
// ----------------------------------------------------------------------

export const INITIAL_PATH = PATH_GENERAL.root;

export const USER_MENU_OPTIONS: UserMenuOptions = [{ label: 'Settings', icon: settings2Fill, linkTo: PATH_DASHBOARD.account }];

export const REQUEST_STATUS = {
  BEFORE_REQUEST: 'enqueteRequest.requestStatus.beforeRequest',
  IN_PROGRESS: 'enqueteRequest.requestStatus.inProgress',
  COMPLETE: 'enqueteRequest.requestStatus.complete',
} as const;
export type RequestStatus = typeof REQUEST_STATUS[keyof typeof REQUEST_STATUS];

export const SURVEY_TYPE = {
  SPECIAL: 'SPECIAL',
  GENERAL: 'GENERAL',
} as const;
export type SurveyType = typeof SURVEY_TYPE[keyof typeof SURVEY_TYPE];

export const ANSWER_UNIT = {
  ORGANIZATION: 'ORGANIZATION',
  PERSONAL: 'PERSONAL',
} as const;
export type AnswerUnit = typeof ANSWER_UNIT[keyof typeof ANSWER_UNIT];

export const FORM_TYPE = {
  QA: 'QA',
  TABLE: 'TABLE',
  SPECIAL: 'SPECIAL',
  SERIES: 'SERIES',
  OVERVIEW: 'OVERVIEW',
} as const;
export type FormType = typeof FORM_TYPE[keyof typeof FORM_TYPE];

export const ANSWER_STATUS = {
  ANSWERED: 'ANSWERED',
  SAVED: 'SAVED',
  RETURNED: 'RETURNED',
} as const;
export type AnswerStatus = typeof ANSWER_STATUS[keyof typeof ANSWER_STATUS];

export const DISCLOSURE_LEVEL = {
  PRIVATE: 'PRIVATE',
  INTERNAL: 'INTERNAL',
  PUBLIC: 'PUBLIC',
} as const;
export type DisclosureLevel = typeof DISCLOSURE_LEVEL[keyof typeof DISCLOSURE_LEVEL];

export const DISCLOSURE_LEVEL_UPDATE_CONFIRM = {
  NO: 'NO',
  YES: 'YES',
} as const;
export type DisclosureLevelUpdateConfirm = typeof DISCLOSURE_LEVEL_UPDATE_CONFIRM[keyof typeof DISCLOSURE_LEVEL_UPDATE_CONFIRM];

export const VISIBLE_TYPE = {
  SELECT: 'SELECT',
  ALL: 'ALL',
} as const;
export type VisibleType = typeof VISIBLE_TYPE[keyof typeof VISIBLE_TYPE];

export const COMPANY = {
  TOYOTA_MOTOR: { name: 'トヨタ自動車株式会社', eng_name: 'TOYOTA MOTOR CORPORATION.' },
  DENSO: { name: '株式会社デンソー', eng_name: 'DENSO CORPORATION.' },
  AISIN: { name: '株式会社　アイシン', eng_name: 'AISIN CO.,LTD.' },
  TOYOTA_BOSHOKU: { name: 'トヨタ紡織株式会社', eng_name: 'TOYOTA BOSHOKU CORPORATION' },
  TOYODA_GOSEI: { name: '豊田合成株式会社', eng_name: 'TOYODA GOSEI CO.,LTD.' },
  TOYOTA_INDUSTRIES: { name: '株式会社豊田自動織機', eng_name: 'TOYOTA INDUSTRIES CORPORATION' },
  JTEKT_CORPORATION: { name: '株式会社ジェイテクト', eng_name: 'JTEKT CORPORATION' },
  AICHI_STEEL: { name: '愛知製鋼株式会社', eng_name: 'AICHI STEEL CORPORATION' },
  TOYOTA_AUTO_BODY: { name: 'トヨタ車体株式会社', eng_name: 'TOYOTA AUTO BODY CO.,LTD.' },
  HINO_MOTORS: { name: '日野自動車株式会社', eng_name: 'HINO MOTORS,LTD' },
  DAIHATSU_MOTOR: { name: 'ダイハツ工業株式会社', eng_name: 'DAIHATSU MOTOR CO.,LTD' },
  SUZUKI_MOTOR: { name: 'スズキ株式会社', eng_name: 'SUZUKI MOTOR CORPORATION' },
  MAZDA_MOTOR: { name: 'マツダ株式会社', eng_name: 'MAZDA MOTOR CORPORATION' },
  SUBARU: { name: '株式会社ＳＵＢＡＲＵ', eng_name: 'SUBARU CORPORATION' },
  KOITO_MANUFACTURING: { name: '株式会社小糸製作所', eng_name: 'KOITO MANUFACTURING CO.,LTD' },
  YAZAKI: { name: '矢崎総業株式会社', eng_name: 'YAZAKI CORPORATION' },
  //系列別追加開発対応
  WOVEN_BY_TOYOTA: { name: 'ウーブン・バイ・トヨタ株式会社', eng_name: 'Woven by Toyota, INC.' },
  TOYOTA_MOTOR_EAST_JAPAN: { name: 'トヨタ自動車東日本株式会社', eng_name: 'TOYOTA MOTOR EAST JAPAN , INC.' },
  TOYOTA_FUDOSAN: { name: 'トヨタ不動産株式会社', eng_name: 'TOYOTA FUDOSAN CO., LTD.' },
  TOYOTA_MOTOR_KYUSHU: { name: 'トヨタ自動車九州株式会社', eng_name: 'TOYOTA MOTOR KYUSHU,INC.' },
  TOYOTA_HOUSING_CORPORATION: { name: 'トヨタホーム株式会社', eng_name: 'TOYOTA HOUSING CORPORATION' },
  TOYOTA_CENTRAL_LABS: { name: '株式会社豊田中央研究所', eng_name: 'TOYOTA CENTRAL R&D LABS.,INC.' },
} as const;

export const SENT_STATUS = {
  UN_SENT: null,
  PROCESSING: 'PROCESSING',
  SENT: 'SENT',
  SENT_FAILED: 'SENT_FAILED',
} as const;
export type SentStatus = typeof SENT_STATUS[keyof typeof SENT_STATUS];

export const DELIMITER = '\r\n';

export const LIST_STATE_KEY_PREFIX = {
  COLUMN: 'ColumnStateOf',
  FILTER: 'FilterStateOf',
  RESPONDENT_DATA: 'SelectedRespondentDataOf',
} as const;

export const ORG_LEVEL_TYPE = { COMPANY: 'COMPANY', SBU: 'SBU', DIVISION: 'DIVISION', DEPARTMENT: 'DEPARTMENT', GROUP: 'GROUP' } as const;
export type OrgLevelType = typeof ORG_LEVEL_TYPE[keyof typeof ORG_LEVEL_TYPE];

export const ACCESS_ROLE_TYPE = {
  FULL: '1',
  ANSWER_READ: '2',
} as const;

// 取引先主要22社
export const TOP_GLOBAL_COMPANY = {
  TOYOTA_MOTOR: 'A1453130' as string,
  DENSO: 'A1502340' as string,
  AISIN: 'A1100960' as string,
  TOYOTA_BOSHOKU: 'A1456760' as string,
  TOYODA_GOSEI: 'A1456040' as string,
  TOYOTA_INDUSTRIES: 'A1456280' as string,
  JTEKT_CORPORATION: 'A1250020' as string,
  AICHI_STEEL: 'A1102000' as string,
  TOYOTA_AUTO_BODY: 'A1452950' as string,
  HINO_MOTORS: 'A1553450' as string,
  DAIHATSU_MOTOR: 'A1379390' as string,
  SUZUKI_MOTOR: 'A1324310' as string,
  MAZDA_MOTOR: 'A1599560' as string,
  SUBARU: 'A1566790' as string,
  KOITO_MANUFACTURING: 'A1245170' as string,
  YAZAKI: 'A1649300' as string,
  //系列別追加開発対応
  WOVEN_BY_TOYOTA: 'A114CT60' as string,
  TOYOTA_MOTOR_EAST_JAPAN: 'A1212290' as string,
  TOYOTA_FUDOSAN: 'A1434100' as string,
  TOYOTA_MOTOR_KYUSHU: 'A1453090' as string,
  TOYOTA_HOUSING_CORPORATION: 'A1453660' as string,
  TOYOTA_CENTRAL_LABS: 'A1456580' as string,
} as const;

// memo:
// トヨタ車体、ダイハツ、日野自動車、ウーブン・バイ・トヨタ、トヨタ自動車東日本、トヨタ自動車九州、豊田中央研究所追加の7社のA1コードです。
// 上記7社は、トヨタ自動車の子会社ではあるが、系列別調査票においてはトヨタ自動車の子会社としては扱わず、親会社として扱う必要がある。
// そのため、以下7社は例外の対象会社としてA1コードをハードコーディングで保持する。
export const COMPLICATED_CUSTOMERS = [
  TOP_GLOBAL_COMPANY.TOYOTA_AUTO_BODY,
  TOP_GLOBAL_COMPANY.HINO_MOTORS,
  TOP_GLOBAL_COMPANY.DAIHATSU_MOTOR,
  TOP_GLOBAL_COMPANY.WOVEN_BY_TOYOTA,
  TOP_GLOBAL_COMPANY.TOYOTA_MOTOR_EAST_JAPAN,
  TOP_GLOBAL_COMPANY.TOYOTA_CENTRAL_LABS,
  TOP_GLOBAL_COMPANY.TOYOTA_MOTOR_KYUSHU,
];

// 債権債務入力対象会社
//系列別追加開発対応。ウーブン・バイ・トヨタ、トヨタ自動車東日本、トヨタ自動車九州、豊田中央研究所追加
export const DISPLAY_ACCOUNTS_CUSTOMER = [
  TOP_GLOBAL_COMPANY.TOYOTA_MOTOR,
  TOP_GLOBAL_COMPANY.TOYOTA_AUTO_BODY,
  TOP_GLOBAL_COMPANY.HINO_MOTORS,
  TOP_GLOBAL_COMPANY.DAIHATSU_MOTOR,
  TOP_GLOBAL_COMPANY.WOVEN_BY_TOYOTA,
  TOP_GLOBAL_COMPANY.TOYOTA_MOTOR_EAST_JAPAN,
  TOP_GLOBAL_COMPANY.TOYOTA_CENTRAL_LABS,
  TOP_GLOBAL_COMPANY.TOYOTA_MOTOR_KYUSHU,
];

// アンケート集計対象外組織
// 回答結果に保険部の情報は除外するため、ここで保険部の情報を持つ
export const INSURANCE_INFO = {
  DIVISION_CODE: 'AA1600',
  SBU_CODE: 'AUJ300',
  DEPARTMENT_CODE: 'A07000',
};

export const DATA_ORIGIN = {
  CATS: 'CATS',
} as const;
export type DataOrigin = typeof DATA_ORIGIN[keyof typeof DATA_ORIGIN];

// 系列別　Excelインポートエラーコード
export const IMPORT_WARNING_CODE = {
  DELETED_CUSTOMER: 0,
  OUT_OF_SERIES_CUSTOMER: 1,
} as const;
export type SeriesWarningCode = typeof IMPORT_WARNING_CODE[keyof typeof IMPORT_WARNING_CODE];

// 概況表　期間指定方法
export const SPECIFY_TYPE = {
  MONTH: 'MONTH',
  PERIOD: 'PERIOD',
} as const;
export type SpecifyType = typeof SPECIFY_TYPE[keyof typeof SPECIFY_TYPE];

// お知らせカテゴリー
export const NOTIFICATION_CATEGORIES = [
  'notifications.form.categories.releaseNote',
  'notifications.form.categories.issue',
  'notifications.form.categories.anouncement',
];

// 雇用形態_人件費人員（ドロップダウンで利用）
export const PERSONNEL_EMPLOYMENT = [
  // 正社員
  {
    VALUE: 'regularEmployee',
    LABEL: 'select.personnelEmploymentStatus.regularEmployee',
  },
  // TTCからの出向受入
  {
    VALUE: 'secondmentFromTTC',
    LABEL: 'select.personnelEmploymentStatus.secondmentFromTTC',
  },
  // 2以外の出向受入
  {
    VALUE: 'secondmentFromOther',
    LABEL: 'select.personnelEmploymentStatus.secondmentFromOther',
  },
  // 派遣、嘱託、パート、アルバイト
  {
    VALUE: 'dispatchEtc',
    LABEL: 'select.personnelEmploymentStatus.dispatchEtc',
  },
];

// 雇用形態_製造原価人員（ドロップダウンで利用）
export const MANUFYEAR_PLANUR_EMPLOYMENT = [
  // 正社員
  {
    VALUE: 'regularEmployee',
    LABEL: 'select.manufyearPlanurEmploymentStatus.regularEmployee',
  },
  // 1以外の従業員/派遣/その他
  {
    VALUE: 'otherEmployees',
    LABEL: 'select.manufyearPlanurEmploymentStatus.otherEmployees',
  },
];

// 重点分野（ドロップダウンで利用）
export const PRIORITY_DOMAIN = [
  // ネクストモビリティ
  {
    VALUE: 'nextMobility',
    LABEL: 'select.priorityDomain.nextMobility',
  },
  // 再生可能エネルギー・エネルギーマネジメント
  {
    VALUE: 'renewableEnergyAndEnergyManagement',
    LABEL: 'select.priorityDomain.renewableEnergyAndEnergyManagement',
  },
  // アフリカ
  {
    VALUE: 'africa',
    LABEL: 'select.priorityDomain.africa',
  },
  // 循環型静脈
  {
    VALUE: 'circularEconomy',
    LABEL: 'select.priorityDomain.circularEconomy',
  },
  // バッテリー
  {
    VALUE: 'batteries',
    LABEL: 'select.priorityDomain.batteries',
  },
  // 水素・代替燃料
  {
    VALUE: 'hydrogenAndAlternativeFuels',
    LABEL: 'select.priorityDomain.hydrogenAndAlternativeFuels',
  },
  // Economy of Life
  {
    VALUE: 'economyOfLife',
    LABEL: 'select.priorityDomain.economyOfLife',
  },
  // 基盤事業
  {
    VALUE: 'organicBusiness',
    LABEL: 'select.priorityDomain.organicBusiness',
  },
];

export const ONLY_ANSWER_ROLES: Role[] = [BonsaiRole.ANSWER_SERIES];

export const SELECT_RESPONDENT_LINE_MAX_LENGTH: number = 5000;
// Marsからの取得件数
export const SELECT_RESPONDENT_MARS_MAX_RETRIEVED: number = 1000;
// タイムアウト時間を5分とする
export const EXEC_TIME_OUT: number = 300000;
// アドレス一括登録時エラー 依頼先登録エラー(組織情報解決不可)
export const BULK_CREATE_EMAIL_BAD_REQUEST: string = '400';
// アドレス一括登録時エラー 権限エラー
export const BULK_CREATE_EMAIL_NO_ACCESS: string = '403';
// アドレス一括登録時エラー 依頼先登録エラー
export const BULK_CREATE_EMAIL_NO_ADDRESS: string = '404';
// アドレス一括登録時エラー その他エラー
export const BULK_CREATE_EMAIL_ETC_ERROR: string = '999';
