import { parse } from 'csv-parse/browser/esm/sync';
import { isString } from 'lodash';
import { FieldValues, UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Question } from 'src/@types/generalEnquete';
import { DELIMITER } from 'src/constants';
import { setQuestionsEng, setQuestionsTranslationData } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { dispatch } from 'src/redux/store';
import { TRANSLATION_SUPPORT_FILE } from '../constant/constant';

interface TranslationTableObject {
  [key: string]: string;
}
export interface TranslationTable extends Array<TranslationTableObject> {}

const messageKey = 'enqueteGeneralCommon.translationSupport.message.error';

export const useTranslationUpload = (setError?: UseFormSetError<FieldValues>, clearErrors?: UseFormClearErrors<FieldValues>) => {
  const { t } = useTranslation();
  let msg: string = '';

  /**
   * For Uploading file
   * @param e
   * @returns file
   */
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    msg = '';
    const file = e.target.files?.[0];
    if (clearErrors && setError) {
      clearErrors('file');
      if (!validateFile(file)) {
        return undefined;
      }
    }
    return file;
  };

  /**
   * Validate uploaded file. Check if it is CSV file and the size is less than 1MB
   * @param file
   * @returns boolean エラーがない場合true,エラーの場合false
   */
  const validateFile = (file: File | undefined) => {
    if (!file || !setError) return true;

    if (file.type !== TRANSLATION_SUPPORT_FILE.FILE_TYPE) {
      const msgWork = t(`${messageKey}.fileType`);
      setError('file', { type: 'manual', message: appendErrorMessage(msgWork) });
      return false;
    }
    if (file.size > TRANSLATION_SUPPORT_FILE.MAXIMUM_FILE_SIZE) {
      const msgWork = t(`${messageKey}.fileSize`);
      setError('file', { type: 'manual', message: appendErrorMessage(msgWork) });
      return false;
    }
    return true;
  };

  /**
   * アップロードしたファイルを読み込み、 questionsTranslationData に設定する
   * @param file
   * @param questionsJpn
   */
  const readFileAndSetState = (file: File, questionsJpn: Question[]) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result;
      if (result) {
        const translationList = parse(result.toString(), {
          record_delimiter: DELIMITER,
          relax_quotes: true,
          relax_column_count: true,
        });
        const checkResult = validateUploadedFile(translationList);
        if (checkResult) {
          if (isString(result)) {
            dispatch(setQuestionsTranslationData(translationList));
          } else {
            dispatch(setQuestionsTranslationData([]));
          }
          const translationTable = convertFileArrayToTranslationTable(translationList);
          dispatch(setQuestionsEng(replaceTranslationQuestionsData(questionsJpn, translationTable)));
        }
      } else if (setError) {
        // error: no result
        const msgWork = t(`${messageKey}.emptyFile`);
        setError('file', { type: 'manual', message: appendErrorMessage(msgWork) });
      }
    };
    reader.readAsText(file);
  };

  /**
   * convert fileArray to translationTable
   * fileArray:string[][] → translationTable:TranslationTable に変換する
   * @param fileArray
   * @returns TranslationTableの配列
   */
  const convertFileArrayToTranslationTable = (fileArray: string[][]): TranslationTableObject[] => {
    if (!fileArray || !fileArray.length || fileArray.length === 0) return [];
    let newTranslationTable: TranslationTable = [];
    fileArray.forEach((value) => {
      newTranslationTable.push({ ja: value[0] ?? '', en: value[1] ?? '' });
    });
    return newTranslationTable;
  };

  /**
   * JSONオブジェクトの特定のキーの特定の文字列を置換する処理
   * @param obj 置換対象JSONオブジェクト
   * @param keys キー配列
   * @param target 置換対象文字列
   * @param replacement 置換後文字列
   */
  const replaceValue = (obj: any, keys: string[], target: string, replacement: string): void => {
    // オブジェクト内の全てのプロパティに対してループを実行します
    for (const prop in obj) {
      // プロパティがオブジェクトの場合、再帰的に関数を呼び出します
      if (typeof obj[prop] === 'object') {
        replaceValue(obj[prop], keys, target, replacement);
      }
      // プロパティが目標のキーに一致し、かつその値がターゲット文字列と同じ場合、値を置換します
      else if (keys.includes(prop) && obj[prop] === target) {
        obj[prop] = obj[prop].replace(target, replacement);
      }
    }
  };

  /**
   * Replace string in enquete data with translation table
   * 日英変換処理。アップロードした変換データをもとに変換を行う
   *
   * @param questionsJpn
   * @param translationsData
   * @returns 日英変換処理後の質問配列
   */
  const replaceTranslationQuestionsData = (questionsJpn: Question[], translationsData: TranslationTable): Question[] => {
    if (!translationsData || translationsData.length === 0) return questionsJpn;
    const newTranslationsData = translationsData.sort((firstObject: TranslationTableObject, secondObject: TranslationTableObject) =>
      firstObject.ja.length > secondObject.ja.length ? -1 : 1,
    );
    // object deep copy
    const questionEng = JSON.parse(JSON.stringify(questionsJpn));
    newTranslationsData.forEach((value) => {
      if (value.ja.length > 0 && value.en.length > 0) {
        replaceValue(questionEng, ['title', 'label', 'errorMessage'], value.ja, value.en);
      }
    });

    return questionEng;
  };

  /**
   * アップロードされたファイルのバリデーションエラーをセットする
   *
   * @param index 行(0始まり)
   * @param errMsg エラーメッセージ
   */
  const setUploadedFileValidationError = (index: number, errMsg: string) => {
    const msgWork = t(`${messageKey}.line`) + (index + 1) + ' ' + errMsg;
    setError && setError('file', { type: 'manual', message: appendErrorMessage(msgWork) });
  };

  /**
   * Validate uploaded file as CSV format, must be needed to be modified
   * アップロードファイル内容チェック
   *
   * @param translationList 変換データの二次元配列
   * @returns チェック結果
   */
  const validateUploadedFile = (translationList: string[][]): boolean => {
    let isSuccess: boolean = true;
    translationList.forEach((translationRow, index) => {
      // Check if the array has 2 elements
      if (translationRow.length !== 2) {
        setUploadedFileValidationError(index, t(`${messageKey}.incorrectNumberOfColumns`));
        isSuccess = false;
      }
      // all elements must be not empty including multiple space and tab
      translationRow.forEach((value, colIndex) => {
        if (colIndex < TRANSLATION_SUPPORT_FILE.COL_LENGTH && [undefined, '', '""', "''"].includes(value.replace(/\s+/g, ''))) {
          setUploadedFileValidationError(index, t(`${messageKey}.required`));
          isSuccess = false;
        }
        // Input characters must be within 100 characters
        if (colIndex < TRANSLATION_SUPPORT_FILE.COL_LENGTH && value.length > TRANSLATION_SUPPORT_FILE.MAXIMUM_STRING) {
          setUploadedFileValidationError(index, t(`${messageKey}.maximumString`));
          isSuccess = false;
        }
      });
    });
    return isSuccess;
  };

  /**
   * エラーメッセージが既に組み込まれていない場合にのみ append
   * @param msgWork
   * @returns エラーメッセージ
   */
  const appendErrorMessage = (msgWork: string): string => {
    // memo: エラーメッセージが既に組み込まれていない場合にのみ append
    if (msg.indexOf(msgWork) === -1) {
      msg = msg && msg.length !== 0 ? msg + DELIMITER + msgWork : msgWork;
    }
    return msg;
  };

  /**
   * csv文字列を配列に変換する
   *
   * @param csv csv文字列
   * @returns 配列
   */
  const convertCsvToArray = (csv: string): string[] => {
    return parse(csv, {
      cast: (value, context) => {
        if (context.quoting) {
          return `"${value}"`;
        }
        return value;
      },
      record_delimiter: DELIMITER,
      relax_quotes: true,
    });
  };

  return {
    handleOnChange,
    replaceTranslationQuestionsData,
    convertFileArrayToTranslationTable,
    validateFile,
    validateUploadedFile, // memo: no use, but for unit testing
    readFileAndSetState,
    convertCsvToArray, // memo: no use, but for unit testing
  };
};
