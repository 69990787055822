import { useSnackbar } from 'notistack5';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CustomerSearchCondition, useCustomerApi } from 'src/api/useCustomerApi';
import { useEnqueteAnswerInputApi } from 'src/api/useEnqueteAnswerInputApi';
import { TOP_GLOBAL_COMPANY } from 'src/constants';
import { SeriesSurveyFormAnswerData } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import {
  AMOUNT_SOURCE_TYPE,
  setCurrency,
  setCustomerGroups,
  setCustomers,
  setInitIsUsePastData,
  setIsUsePastData,
  setNumberOfDecimalPlaces,
} from 'src/features/general/enquete-answer/store/seriesAnswerSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { useCalculateFiscalYear } from './useCalculateFiscalYear';
import { useGetCurrency } from './useGetCurrency';
import { useProposalInitValue } from './useProposalInitValue';
import { useTradingVolumeForm } from './useTradingVolumeForm';

export const useGetDefaultValue = () => {
  const dispatch = useAppDispatch();
  const { initDefaultValues } = useTradingVolumeForm();
  const { getCustomersByFilter } = useCustomerApi();
  const { initialValueProposalAll } = useEnqueteAnswerInputApi();
  const { setCustomerGroup } = useProposalInitValue();
  const { getCurrencies, getRateForCurrency } = useGetCurrency();
  const { currentYear } = useCalculateFiscalYear();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { enqueteAnswerData, respondentTargetData } = useAppSelector((state) => state.enqueteAnswer);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const topGlobalCustomer = Object.values(TOP_GLOBAL_COMPANY);

  const where: CustomerSearchCondition = {
    topGlobalCustomerCode: topGlobalCustomer,
  };

  // 保存データがあるかどうかを確認して、あれば保存したデータ、なければ過去データor cats連携データ
  const setInitialData = async () => {
    try {
      const promiseResult = await Promise.all([
        // 主要22社とその子会社、かつアンケートの対象年月と比較して、有効開始日が１ヶ月以上前の取引先を取得
        getCustomersByFilter(where),

        // 通貨の取得
        getCurrencies(id ?? ''),

        // 初期値提案値を取得
        // 回答データが存在する場合は取得しない
        ...('id' in enqueteAnswerData ? [] : [initialValueProposalAll(respondentTargetData.id)]),
      ]);
      const customers = promiseResult[0];
      const currency = promiseResult[1];
      const initValue = promiseResult[2];

      if (!customers || !currency) return;

      dispatch(setInitIsUsePastData());
      dispatch(setCustomers(customers));

      // 回答データを取得
      const counterparties = (enqueteAnswerData?.answerData?.answerInputData as SeriesSurveyFormAnswerData)?.tabData[0]?.counterparties;

      // 回答データが存在する場合、回答データで
      const { customerGroups, arraySourceType, defaultCurrency } = await setCustomerGroup(counterparties ?? initValue, customers);

      // 取引先件数が50件以上の場合ローディングに時間がかかるため警告のスナックバーを表示
      // TODO::性能的にserver側で実装したいところではあるが回答データが存在する場合どうするか。
      // 現状ではグッローバルステートから取得しているが、server側に移行すべきなのかも、検討が必要
      let customerLength: number = 0;
      customerGroups.forEach((group) => {
        customerLength += group.childrenCustomer.length;
      });

      if (customerLength >= 50)
        enqueueSnackbar(t('enqueteAnswerInput.message.takingALongTimeToLoad'), { variant: 'warning', autoHideDuration: 10000 });
      dispatch(setCustomerGroups(customerGroups));
      dispatch(setIsUsePastData(arraySourceType.includes(AMOUNT_SOURCE_TYPE.PRE_ANSWERED)));
      dispatch(setCurrency(defaultCurrency));
      // ラベル用の債権債務残高を取得
      initDefaultValues(respondentTargetData.id, customerGroups);
      // 小数点以下桁数のセット
      const currencyData = currency.find((elm) => elm.currencyCode === defaultCurrency);
      dispatch(setNumberOfDecimalPlaces(currencyData ? currencyData.numberOfDecimalPlaces ?? 0 : 0));
      // 為替レートを取得
      await getRateForCurrency(defaultCurrency, currentYear);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // 初回レンダリング時のみ
  useEffect(() => {
    setInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading };
};
