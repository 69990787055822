import { AutocompleteChangeReason } from '@mui/material';
import _ from 'lodash';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InputTemporaryOrganizationTypes,
  TemporaryOrganizationDataTypes,
  useTemporaryOrganizationApi,
} from 'src/api/survey-edit/useTemporaryOrganizationApi';
import { SegmentTypeList } from 'src/features/general/enquete-create/components/modals/temporary-organization-detail/components/TemporaryOrganizationContent';
import { InitOrganizationData } from 'src/features/general/enquete-create/components/modals/temporary-organization-detail/components/TemporaryOrganizationDetailModal';
import { CreateOrgTypeList } from 'src/features/general/enquete-create/components/tab-containers/shared-tabs/temporary-organization/components/TemporaryOrganizationTab';
import {
  SearchOrganizationConditionTypes,
  SearchOrganizationMultiConditionTypes,
  useSearchOrganizationConditions,
} from 'src/features/general/enquete-create/hooks/useSearchOrganizationConditions';
import useLocales from 'src/hooks/useLocales';
import { addSuffix, switchLabel } from 'src/utils/labelUtils';
import { v4 as uuidv4 } from 'uuid';
import { useTemporaryOrganization } from '../../../tab-containers/shared-tabs/temporary-organization/hooks/useTemporaryOrganization';
import { useTemporaryOrganizationValidation } from './useTemporaryOrganizationValidation';

// オートコンプリート 構成
export interface AutocompleteConditionProps {
  id: string;
  label: string;
  key: string;
  list: SearchOrganizationConditionTypes[];
  onChange: (value: SearchOrganizationConditionTypes, reason: AutocompleteChangeReason) => void;
}

// オートコンプリート データ格納用
export interface InputValuesProps {
  divisionCode: string;
  divisionNameJpn: string;
  divisionNameEng: string;
  sbuCode: string;
  sbuNameJpn: string;
  sbuNameEng: string;
  departmentCode: string;
  departmentNameJpn: string;
  departmentNameEng: string;
  groupCode: string;
  groupNameJpn: string;
  groupNameEng: string;
  regionCode: string;
  regionNameJpn: string;
  regionNameEng: string;
  countryCode: string;
  countryNameJpn: string;
  countryNameEng: string;
  rank: string;
}

// 初期値データ格納
export const initInputVules: InputValuesProps = {
  divisionCode: '',
  divisionNameJpn: '',
  divisionNameEng: '',
  sbuCode: '',
  sbuNameJpn: '',
  sbuNameEng: '',
  departmentCode: '',
  departmentNameJpn: '',
  departmentNameEng: '',
  groupCode: '',
  groupNameJpn: '',
  groupNameEng: '',
  regionCode: '',
  regionNameJpn: '',
  regionNameEng: '',
  countryCode: '',
  countryNameJpn: '',
  countryNameEng: '',
  rank: '',
};

// オートコンプリート 初期値
export const initAutocompleteValue: SearchOrganizationConditionTypes = { key: '', label: '' };

// オートコンプリート onChange内のReason定義
export enum OnChangeReasonTypeList {
  CREATE_OPTION = 'createOption',
  SELECT_OPTION = 'selectOption',
  REMOVE_OPTION = 'removeOption',
  BLUR = 'blur',
  CLEAR = 'clear',
}

// 検索用条件
export interface SearchConditonProps {
  divisionCode: string;
  sbuCode: string;
  departmentCode: string;
  groupCode: string;
}

// 検索用条件初期値
export const initSearchCondition: SearchConditonProps = {
  divisionCode: '',
  sbuCode: '',
  departmentCode: '',
  groupCode: '',
};

interface Props {
  inputData: TemporaryOrganizationDataTypes;
  createType: CreateOrgTypeList | null;
  surveyDetailId: string;
}

// ダミー組織コード用 接頭詞
export const dummyOrganizationCodePrefix = 'TEMPORARY-';

const useTemporaryOrganizationModal = (props: Props) => {
  const { inputData, createType, surveyDetailId } = props;
  const { t } = useTranslation();
  const { isJapanese } = useLocales();

  const { createTemporaryOrganization, updateTemporaryOrganization } = useTemporaryOrganizationApi();
  const {
    divisions,
    sbus,
    departments,
    groups,
    bonsaiRegions,
    bonsaiCountries,
    ranks,
    isLoading: isLoadingInitialData,
    makeSbus,
    makeDepartments,
    makeGroups,
    makeBonsaiCountries,
  } = useSearchOrganizationConditions({
    surveyDetailId: surveyDetailId,
    isOmitTemporaryOrganization: false,
  });
  const { updateRefreshCounter, closeEditModal, startLoading, stopLoading } = useTemporaryOrganization();
  const { checkValidation } = useTemporaryOrganizationValidation();

  /***********************************************************************************
   * Use Status
   ***********************************************************************************/
  // データ通信用
  const [baseOrganizationData, setBaseOrganizationData] = useState<TemporaryOrganizationDataTypes>(InitOrganizationData);
  // オートコンプリート データ格納用
  const [selectAutocompleteValues, setSelectAutocompleteValues] = useState<InputValuesProps>(initInputVules);

  // コード
  const [companyCode, setCompanyCode] = useState<string>('');

  // 日本語名称
  const [companyNameJpn, setCompanyNameJpn] = useState<string>('');
  const [divisionNameJpn, setDivisionNameJpn] = useState<string>('');
  const [sbuNameJpn, setSbuNameJpn] = useState<string>('');
  const [departmentNameJpn, setDepartmentNameJpn] = useState<string>('');
  const [groupNameJpn, setGroupNameJpn] = useState<string>('');

  // 英語名称
  const [companyNameEng, setCompanyNameEng] = useState<string>('');
  const [divisionNameEng, setDivisionNameEng] = useState<string>('');
  const [sbuNameEng, setSbuNameEng] = useState<string>('');
  const [departmentNameEng, setDepartmentNameEng] = useState<string>('');
  const [groupNameEng, setGroupNameEng] = useState<string>('');

  // チェック判定
  const [isCheckDivision, setIsCheckDivision] = useState<boolean>(false);
  const [isCheckSbu, setIsCheckSbu] = useState<boolean>(false);
  const [isCheckDepartment, setIsCheckDepartment] = useState<boolean>(false);
  const [isCheckGroup, setIsCheckGroup] = useState<boolean>(false);

  // 検索リスト
  const [divisionOptionsList, setDivisionOptionsList] = useState<string[]>([]);
  const [sbuOptionsList, setSbuOptionsList] = useState<string[]>([]);
  const [departmentOptionsList, setDepartmentOptionsList] = useState<string[]>([]);
  const [groupOptionsList, setGroupOptionsList] = useState<string[]>([]);

  // オートコンプリート 表示用データ
  const [selectValueDivision, setSelectValueDivision] = useState<SearchOrganizationConditionTypes | null>(null);
  const [selectValueSbu, setSelectValueSbu] = useState<SearchOrganizationConditionTypes | null>(null);
  const [selectValueDepartment, setSelectValueDepartment] = useState<SearchOrganizationConditionTypes | null>(null);
  const [selectValueGroup, setSelectValueGroup] = useState<SearchOrganizationConditionTypes | null>(null);
  const [selectValueRegion, setSelectValueRegion] = useState<SearchOrganizationConditionTypes | null>(null);
  const [selectValueCountry, setSelectValueCountry] = useState<SearchOrganizationConditionTypes | null>(null);
  const [selectValueRank, setSelectValueRank] = useState<SearchOrganizationConditionTypes | null>(null);

  /***********************************************************************************
   * Functions
   ***********************************************************************************/
  // 組織情報初期化
  const setOrganizationInputData = async () => {
    // 組織コード
    setCompanyCode(inputData.companyCode);

    // 日本語
    setCompanyNameJpn(inputData.companyNameJpn);
    setDivisionNameJpn(inputData.divisionNameJpn);
    setSbuNameJpn(inputData.sbuNameJpn);
    setDepartmentNameJpn(inputData.departmentNameJpn);
    setGroupNameJpn(inputData.groupNameJpn);

    // 英語
    setCompanyNameEng(inputData.companyNameEng);
    setDivisionNameEng(inputData.divisionNameEng);
    setSbuNameEng(inputData.sbuNameEng);
    setDepartmentNameEng(inputData.departmentNameEng);
    setGroupNameEng(inputData.groupNameEng);

    // オートコンプリート用データ
    setSelectValueRegion({ key: inputData.regionCode, label: switchLabel(inputData.regionNameJpn, inputData.regionNameEng, isJapanese) });
    setSelectValueCountry({ key: inputData.countryCode, label: switchLabel(inputData.countryNameJpn, inputData.countryNameEng, isJapanese) });
    // 極コードが指定されている場合、国情報を取得する
    if (inputData.countryCode !== '') {
      await makeBonsaiCountries(inputData.regionCode ? inputData.regionCode : undefined);
    }
    setSelectValueRank({ key: inputData.rank, label: inputData.rank });

    // データ格納
    setBaseOrganizationData(inputData);
  };

  // 単体新規作成時に単体情報を格納
  // Todo: 将来的にS500情報/極情報/国情報をAPI経由で最新を取得する
  const setTtcInitData = () => {
    const companyData: { companyCode: string; companyNameJpn: string; companyNameEng: string; companyAbbreviation: string } = {
      companyCode: 'S500',
      companyNameJpn: '豊田通商(株)',
      companyNameEng: 'Toyota Tsusho Corporation.',
      companyAbbreviation: '豊通',
    };
    const regionData: { regionCode: string; regionNameJpn: string; regionNameEng: string } = {
      regionCode: '001',
      regionNameJpn: '日本',
      regionNameEng: 'Japan',
    };
    const countryData: { countryCode: string; countryNameJpn: string; countryNameEng: string } = {
      countryCode: '29',
      countryNameJpn: '日本',
      countryNameEng: 'JAPAN',
    };
    const rank = { rank: '' };

    setCompanyCode(companyData.companyCode);
    setCompanyNameJpn(companyData.companyNameJpn);
    setCompanyNameEng(companyData.companyNameEng);

    setSelectValueRegion({ key: regionData.regionCode, label: switchLabel(regionData.regionNameJpn, regionData.regionNameEng, isJapanese) });
    setSelectValueCountry({ key: countryData.countryCode, label: switchLabel(countryData.countryNameJpn, countryData.countryNameEng, isJapanese) });
    setSelectValueRank({ key: inputData.rank, label: rank.rank });

    setBaseOrganizationData({ ...baseOrganizationData, ...companyData, ...regionData, ...countryData, ...rank });
  };

  // AutoCompleteコールバック定義
  const handleAutoCompleteChange = async (key: string, value: SearchOrganizationConditionTypes, list: SearchOrganizationMultiConditionTypes[]) => {
    // 表示用データ抽出
    const selectedForm: SearchOrganizationMultiConditionTypes[] = list.filter((v) => v.key === value.key);
    // 選択されたデータを整形（チェックが外れたら、初期データ）
    const selectValue =
      selectedForm.length > 0
        ? { key: value.key, label: getAutocompleteLabel(selectedForm[0].label.jpn, selectedForm[0].label.eng, key, value.key) }
        : null;

    switch (key) {
      case SegmentTypeList.division:
        await makeSbus(value.key ?? undefined);
        await makeDepartments(value.key ?? undefined);
        await makeGroups(value.key ?? undefined);
        setSelectValueDivision(selectValue);
        break;

      case SegmentTypeList.sbu:
        await makeDepartments(selectValueDivision?.key, value.key ?? undefined);
        await makeGroups(selectValueDivision?.key, value.key ?? undefined);
        setSelectValueSbu(selectValue);
        break;

      case SegmentTypeList.department:
        await makeGroups(selectValueDivision?.key, selectValueSbu?.key, value.key ?? undefined);
        setSelectValueDepartment(selectValue);
        break;

      case SegmentTypeList.group:
        setSelectValueGroup(selectValue);
        break;

      case SegmentTypeList.region:
        makeBonsaiCountries(value.key ? value.key : undefined);
        setSelectValueRegion(selectValue);
        break;

      case SegmentTypeList.country:
        setSelectValueCountry(selectValue);
        break;
    }

    // 削除された場合に下位セグメントを削除
    clearLowSegment(key, false);

    // 格納フォーマット条件分岐
    let updateData = {};
    if (key === SegmentTypeList.rank) {
      updateData = {
        [`${SegmentTypeList.rank}`]: selectedForm.length > 0 ? selectedForm[0].key : '',
      };
    } else {
      updateData = {
        [`${key}Code`]: selectedForm.length > 0 ? selectedForm[0].key : '',
        [`${key}NameJpn`]: selectedForm.length > 0 ? selectedForm[0].label.jpn : '',
        [`${key}NameEng`]: selectedForm.length > 0 ? selectedForm[0].label.eng : '',
      };
    }

    // 登録用オブジェクトに格納
    setSelectAutocompleteValues((prevState: InputValuesProps) => {
      return {
        ...prevState,
        ...updateData,
      };
    });
  };

  /**
   * 削除された場合に下位セグメントを削除
   * @param key 削除対象key
   * @param isKeyClear 削除対象keyの削除フラグ
   */
  const clearLowSegment = (key: string, isKeyClear: boolean) => {
    // クリア対象格納
    let clearSegment: string[] = [];

    switch (key) {
      case SegmentTypeList.division:
        setSelectValueSbu(initAutocompleteValue);
        setSelectValueDepartment(initAutocompleteValue);
        setSelectValueGroup(initAutocompleteValue);
        if (isKeyClear) {
          setSelectValueDivision(initAutocompleteValue);
          clearSegment.push(SegmentTypeList.division);
        }
        clearSegment.push(SegmentTypeList.sbu, SegmentTypeList.department, SegmentTypeList.group);
        break;

      case SegmentTypeList.sbu:
        setSelectValueDepartment(initAutocompleteValue);
        setSelectValueGroup(initAutocompleteValue);
        if (isKeyClear) {
          setSelectValueSbu(initAutocompleteValue);
          clearSegment.push(SegmentTypeList.sbu);
        }
        clearSegment.push(SegmentTypeList.department, SegmentTypeList.group);
        break;

      case SegmentTypeList.department:
        setSelectValueGroup(initAutocompleteValue);
        if (isKeyClear) {
          setSelectValueDepartment(initAutocompleteValue);
          clearSegment.push(SegmentTypeList.department);
        }
        clearSegment.push(SegmentTypeList.group);
        break;

      case SegmentTypeList.group:
        if (isKeyClear) {
          setSelectValueGroup(initAutocompleteValue);
          clearSegment.push(SegmentTypeList.group);
        }
        break;
    }

    // 対象の登録用オブジェクトをクリア
    clearSegment.forEach((segmentName: string) => {
      setSelectAutocompleteValues((prevState: InputValuesProps) => {
        return {
          ...prevState,
          ...{
            [`${segmentName}Code`]: '',
            [`${segmentName}NameJpn`]: '',
            [`${segmentName}NameEng`]: '',
          },
        };
      });
    });
  };

  // AutoComplete構成内容定義
  const autocompleteConditionProps = useCallback(
    (
      id: string,
      label: string,
      value: SearchOrganizationConditionTypes | null,
      multiLabellist: SearchOrganizationMultiConditionTypes[],
    ): AutocompleteConditionProps => {
      const list = multiLabellist.map((v) => {
        return {
          ...v,
          ...{
            label: getAutocompleteLabel(v.label.jpn, v.label.eng, id, v.key),
          },
        };
      });

      return {
        id: id,
        label,
        key: value ? value.key : '',
        list,
        onChange: (value: SearchOrganizationConditionTypes) => handleAutoCompleteChange(id, value, multiLabellist),
      };
    },
    [], // eslint-disable-line
  );

  // ラベル抽出
  const getAutocompleteLabel = (jpn: string | null, eng: string | null, id: string, key: string) => {
    const isBranchKey = id === 'region' || id === 'country';
    return isBranchKey ? switchLabel(jpn, eng, isJapanese) : addSuffix(switchLabel(jpn, eng, isJapanese), key);
  };

  // 本部チェックボックスコールバック
  const callbackCheckBoxDivision = (e: SyntheticEvent<Element, Event>) => {
    // フォーム切り替え
    if (!_.isNull(isCheckDivision) && !isCheckDivision) {
      setIsCheckDivision(true);

      // マニュアルフォームクリア
      setDivisionNameJpn('');
      setDivisionNameEng('');
    } else if (!_.isNull(isCheckDivision) && isCheckDivision) {
      setIsCheckDivision(false);
      setIsCheckSbu(false);
      setIsCheckDepartment(false);
      setIsCheckGroup(false);

      // 削除された場合に下位セグメントを削除
      clearLowSegment(SegmentTypeList.division, true);
    }
  };

  // SBUチェックボックスコールバック
  const callbackCheckBoxSbu = (e: SyntheticEvent<Element, Event>) => {
    // フォーム切り替え
    if (!_.isNull(isCheckSbu) && !isCheckSbu) {
      setIsCheckDivision(true);
      setIsCheckSbu(true);

      // マニュアルフォームクリア
      setDivisionNameJpn('');
      setDivisionNameEng('');
      setSbuNameJpn('');
      setSbuNameEng('');
    } else if (!_.isNull(isCheckSbu) && isCheckSbu) {
      setIsCheckSbu(false);
      setIsCheckDepartment(false);
      setIsCheckGroup(false);

      // 削除された場合に下位セグメントを削除
      clearLowSegment(SegmentTypeList.sbu, true);
    }
  };

  // 部チェックボックスコールバック
  const callbackCheckBoxDepartment = (e: SyntheticEvent<Element, Event>) => {
    // フォーム切り替え
    if (!_.isNull(isCheckDepartment) && !isCheckDepartment) {
      setIsCheckDivision(true);
      setIsCheckSbu(true);
      setIsCheckDepartment(true);

      // マニュアルフォームクリア
      setDivisionNameJpn('');
      setDivisionNameEng('');
      setSbuNameJpn('');
      setSbuNameEng('');
      setDepartmentNameJpn('');
      setDepartmentNameEng('');
    } else if (!_.isNull(isCheckDepartment) && isCheckDepartment) {
      setIsCheckDepartment(false);
      setIsCheckGroup(false);

      // 削除された場合に下位セグメントを削除
      clearLowSegment(SegmentTypeList.department, true);
    }
  };

  // グループチェックボックスコールバック
  const callbackCheckBoxGroup = (e: SyntheticEvent<Element, Event>) => {
    // フォーム切り替え
    if (!_.isNull(isCheckGroup) && !isCheckGroup) {
      setIsCheckDivision(true);
      setIsCheckSbu(true);
      setIsCheckDepartment(true);
      setIsCheckGroup(true);

      // マニュアルフォームクリア
      setDivisionNameJpn('');
      setDivisionNameEng('');
      setSbuNameJpn('');
      setSbuNameEng('');
      setDepartmentNameJpn('');
      setDepartmentNameEng('');
      setGroupNameJpn('');
      setGroupNameEng('');
    } else if (!_.isNull(isCheckGroup) && isCheckGroup) {
      setIsCheckGroup(false);

      // 削除された場合にグループの設定を削除
      clearLowSegment(SegmentTypeList.group, true);
    }
  };

  // 作成
  const insertData = async () => {
    // バリデーション
    const isError = checkValidation({
      createType,
      baseOrganizationData,
      selectAutocompleteValues,
      companyNameJpn,
      companyNameEng,
      divisionNameJpn,
      divisionNameEng,
      sbuNameJpn,
      sbuNameEng,
      departmentNameJpn,
      departmentNameEng,
      groupNameJpn,
      groupNameEng,
    });
    if (isError) return;

    // 未入力だった場合、空文字を指定
    const makeDivisionCode = divisionNameJpn && divisionNameEng ? `${dummyOrganizationCodePrefix}${uuidv4()}` : '';
    const makeSbuCode = sbuNameJpn && sbuNameEng ? `${dummyOrganizationCodePrefix}${uuidv4()}` : '';
    const makeDepartmentCode = departmentNameJpn && departmentNameEng ? `${dummyOrganizationCodePrefix}${uuidv4()}` : '';
    const makeGroupCode = groupNameJpn && groupNameEng ? `${dummyOrganizationCodePrefix}${uuidv4()}` : '';

    // オートコンプリート格納データをマージ
    const data: InputTemporaryOrganizationTypes = {
      companyCode: createType === CreateOrgTypeList.ttc ? baseOrganizationData.companyCode : `${dummyOrganizationCodePrefix}${uuidv4()}`,
      companyNameJpn: companyNameJpn ? companyNameJpn : baseOrganizationData.companyNameJpn,
      companyNameEng: companyNameEng ? companyNameEng : baseOrganizationData.companyNameEng,
      companyAbbreviation: baseOrganizationData.companyAbbreviation,
      divisionCode: isCheckDivision ? selectAutocompleteValues.divisionCode : makeDivisionCode,
      divisionNameJpn: selectAutocompleteValues.divisionNameJpn ? selectAutocompleteValues.divisionNameJpn : divisionNameJpn,
      divisionNameEng: selectAutocompleteValues.divisionNameEng ? selectAutocompleteValues.divisionNameEng : divisionNameEng,
      sbuCode: isCheckSbu ? selectAutocompleteValues.sbuCode : makeSbuCode,
      sbuNameJpn: selectAutocompleteValues.sbuNameJpn ? selectAutocompleteValues.sbuNameJpn : sbuNameJpn,
      sbuNameEng: selectAutocompleteValues.sbuNameEng ? selectAutocompleteValues.sbuNameEng : sbuNameEng,
      departmentCode: isCheckDepartment ? selectAutocompleteValues.departmentCode : makeDepartmentCode,
      departmentNameJpn: selectAutocompleteValues.departmentNameJpn ? selectAutocompleteValues.departmentNameJpn : departmentNameJpn,
      departmentNameEng: selectAutocompleteValues.departmentNameEng ? selectAutocompleteValues.departmentNameEng : departmentNameEng,
      groupCode: isCheckGroup ? selectAutocompleteValues.groupCode : makeGroupCode,
      groupNameJpn: selectAutocompleteValues.groupNameJpn ? selectAutocompleteValues.groupNameJpn : groupNameJpn,
      groupNameEng: selectAutocompleteValues.groupNameEng ? selectAutocompleteValues.groupNameEng : groupNameEng,
      regionCode: selectAutocompleteValues.regionCode ? selectAutocompleteValues.regionCode : baseOrganizationData.regionCode,
      regionNameJpn: selectAutocompleteValues.regionNameJpn ? selectAutocompleteValues.regionNameJpn : baseOrganizationData.regionNameJpn,
      regionNameEng: selectAutocompleteValues.regionNameEng ? selectAutocompleteValues.regionNameEng : baseOrganizationData.regionNameEng,
      countryCode: selectAutocompleteValues.countryCode ? selectAutocompleteValues.countryCode : baseOrganizationData.countryCode,
      countryNameJpn: selectAutocompleteValues.countryNameJpn ? selectAutocompleteValues.countryNameJpn : baseOrganizationData.countryNameJpn,
      countryNameEng: selectAutocompleteValues.countryNameEng ? selectAutocompleteValues.countryNameEng : baseOrganizationData.countryNameEng,
      rank: selectAutocompleteValues.rank ? selectAutocompleteValues.rank : baseOrganizationData.rank,
      orgLevelType: '', // NOTE: server側で判定するため、空文字設定
    };

    // 登録
    startLoading();
    await createTemporaryOrganization(surveyDetailId, data);
    stopLoading();

    // ページグリッド更新
    updateRefreshCounter();
    // モーダルクローズ
    closeEditModal();
  };

  // 更新
  const updateData = async () => {
    //バリデーション
    const isError = checkValidation({
      createType,
      baseOrganizationData,
      selectAutocompleteValues,
      companyNameJpn,
      companyNameEng,
      divisionNameJpn,
      divisionNameEng,
      sbuNameJpn,
      sbuNameEng,
      departmentNameJpn,
      departmentNameEng,
      groupNameJpn,
      groupNameEng,
    });
    if (isError) return;

    // オートコンプリート格納データをマージ
    const data: InputTemporaryOrganizationTypes = {
      companyCode: baseOrganizationData.companyCode,
      companyNameJpn: companyNameJpn ? companyNameJpn : baseOrganizationData.companyNameJpn,
      companyNameEng: companyNameEng ? companyNameEng : baseOrganizationData.companyNameEng,
      companyAbbreviation: baseOrganizationData.companyAbbreviation,
      divisionCode: baseOrganizationData.divisionCode,
      divisionNameJpn: divisionNameJpn ? divisionNameJpn : baseOrganizationData.divisionNameJpn,
      divisionNameEng: divisionNameEng ? divisionNameEng : baseOrganizationData.divisionNameEng,
      sbuCode: baseOrganizationData.sbuCode,
      sbuNameJpn: sbuNameJpn ? sbuNameJpn : baseOrganizationData.sbuNameJpn,
      sbuNameEng: sbuNameEng ? sbuNameEng : baseOrganizationData.sbuNameEng,
      departmentCode: baseOrganizationData.departmentCode,
      departmentNameJpn: departmentNameJpn ? departmentNameJpn : baseOrganizationData.departmentNameJpn,
      departmentNameEng: departmentNameEng ? departmentNameEng : baseOrganizationData.departmentNameEng,
      groupCode: baseOrganizationData.groupCode,
      groupNameJpn: groupNameJpn ? groupNameJpn : baseOrganizationData.groupNameJpn,
      groupNameEng: groupNameEng ? groupNameEng : baseOrganizationData.groupNameEng,
      regionCode: selectAutocompleteValues.regionCode ? selectAutocompleteValues.regionCode : baseOrganizationData.regionCode,
      regionNameJpn: selectAutocompleteValues.regionNameJpn ? selectAutocompleteValues.regionNameJpn : baseOrganizationData.regionNameJpn,
      regionNameEng: selectAutocompleteValues.regionNameEng ? selectAutocompleteValues.regionNameEng : baseOrganizationData.regionNameEng,
      countryCode: selectAutocompleteValues.countryCode ? selectAutocompleteValues.countryCode : baseOrganizationData.countryCode,
      countryNameJpn: selectAutocompleteValues.countryNameJpn ? selectAutocompleteValues.countryNameJpn : baseOrganizationData.countryNameJpn,
      countryNameEng: selectAutocompleteValues.countryNameEng ? selectAutocompleteValues.countryNameEng : baseOrganizationData.countryNameEng,
      rank: selectAutocompleteValues.rank ? selectAutocompleteValues.rank : baseOrganizationData.rank,
      orgLevelType: baseOrganizationData.orgLevelType,
    };

    // 更新
    startLoading();
    await updateTemporaryOrganization(baseOrganizationData.id, data);
    stopLoading();

    // ページグリッド更新
    updateRefreshCounter();
    // モーダルクローズ
    closeEditModal();
  };

  /***********************************************************************************
   * Use Effect
   ***********************************************************************************/
  useEffect(() => {
    // 渡された組織情報をコンポーネント管理ステートに格納

    setOrganizationInputData();
  }, [inputData]); // eslint-disable-line

  useEffect(() => {
    // 単体新規作成時処理
    if (createType === CreateOrgTypeList.ttc) setTtcInitData();
  }, [createType]); // eslint-disable-line

  /***********************************************************************************
   * Definition
   ***********************************************************************************/
  // 本部用オートコンプリート構成
  const autocompleteDivisionProps = autocompleteConditionProps(
    SegmentTypeList.division,
    createType === CreateOrgTypeList.ttc
      ? t('enqueteCreate.temporaryOrganization.modal.form.divisionTypeTtcPullDownName')
      : t('enqueteCreate.temporaryOrganization.modal.form.divisionTypeGroupPullDownName'),
    selectValueDivision,
    divisions,
  );

  // SBU用オートコンプリート構成
  const autocompleteSbuProps = autocompleteConditionProps(
    SegmentTypeList.sbu,
    createType === CreateOrgTypeList.ttc
      ? t('enqueteCreate.temporaryOrganization.modal.form.sbuTypeTtcPullDownName')
      : t('enqueteCreate.temporaryOrganization.modal.form.sbuTypeGroupPullDownName'),
    selectValueSbu,
    sbus,
  );

  // 部用オートコンプリート構成
  const autocompleteDepartmentProps = autocompleteConditionProps(
    SegmentTypeList.department,
    createType === CreateOrgTypeList.ttc
      ? t('enqueteCreate.temporaryOrganization.modal.form.departmentTypeTtcPullDownName')
      : t('enqueteCreate.temporaryOrganization.modal.form.departmentTypeGroupPullDownName'),
    selectValueDepartment,
    departments,
  );

  // グループ用オートコンプリート構成
  const autocompleteGroupProps = autocompleteConditionProps(
    SegmentTypeList.group,
    createType === CreateOrgTypeList.ttc
      ? t('enqueteCreate.temporaryOrganization.modal.form.groupTypeTtcPullDownName')
      : t('enqueteCreate.temporaryOrganization.modal.form.groupTypeGroupPullDownName'),
    selectValueGroup,
    groups,
  );

  // 極用オートコンプリート構成
  const autocompleteRegionProps = autocompleteConditionProps(SegmentTypeList.region, t('common.region'), selectValueRegion, bonsaiRegions);

  // 国用オートコンプリート構成
  const autocompleteCountryProps = autocompleteConditionProps(SegmentTypeList.country, t('common.country'), selectValueCountry, bonsaiCountries);

  // 格付用オートコンプリート構成
  const autocompleteRankProps = {
    id: SegmentTypeList.rank,
    label: t('enqueteCreate.temporaryOrganization.modal.form.rankPullDownName'),
    key: selectValueRank ? selectValueRank.key : '',
    list: ranks,
    onChange: (value: SearchOrganizationConditionTypes, reason: AutocompleteChangeReason) => {
      // 管理用オブジェクトに格納
      setSelectValueRank(value);
      // 登録用オブジェクトに格納
      setSelectAutocompleteValues({
        ...selectAutocompleteValues,
        ...{ rank: value.key },
      });
    },
  };

  return {
    companyCode,
    companyNameJpn,
    divisionNameJpn,
    sbuNameJpn,
    departmentNameJpn,
    groupNameJpn,
    companyNameEng,
    divisionNameEng,
    sbuNameEng,
    departmentNameEng,
    groupNameEng,
    isCheckDivision,
    isCheckSbu,
    isCheckDepartment,
    isCheckGroup,
    selectAutocompleteValues,
    divisionOptionsList,
    sbuOptionsList,
    departmentOptionsList,
    groupOptionsList,
    autocompleteDivisionProps,
    autocompleteSbuProps,
    autocompleteDepartmentProps,
    autocompleteGroupProps,
    autocompleteRegionProps,
    autocompleteCountryProps,
    autocompleteRankProps,
    baseOrganizationData,
    isLoadingInitialData,
    setOrganizationInputData,
    setTtcInitData,
    setCompanyCode,
    setCompanyNameJpn,
    setDivisionNameJpn,
    setSbuNameJpn,
    setDepartmentNameJpn,
    setGroupNameJpn,
    setCompanyNameEng,
    setDivisionNameEng,
    setSbuNameEng,
    setDepartmentNameEng,
    setGroupNameEng,
    setIsCheckDivision,
    setIsCheckSbu,
    setIsCheckDepartment,
    setIsCheckGroup,
    setDivisionOptionsList,
    setSbuOptionsList,
    setDepartmentOptionsList,
    setGroupOptionsList,
    callbackCheckBoxDivision,
    callbackCheckBoxSbu,
    callbackCheckBoxDepartment,
    callbackCheckBoxGroup,
    insertData,
    updateData,
    clearLowSegment,
  };
};

export default useTemporaryOrganizationModal;
