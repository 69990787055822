import axios, { AxiosError } from 'axios';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { apiGateway, portalApi } from 'src/config';
import { OrgLevelType } from 'src/constants';
import { useBearerToken } from '../useBearerToken';
import { OrganizationEntity } from '../useOrganizationApi';

export type SearchCondition = {
  where?: SearchFilter;
  orderBy?: SortCondition;
  skip: number;
  take: number;
};

export type SortCondition = {
  orgLevelType?: 'asc' | 'desc';
  companyCode?: 'asc' | 'desc';
  companyNameJpn?: 'asc' | 'desc';
  companyNameEng?: 'asc' | 'desc';
  divisionCode?: 'asc' | 'desc';
  divisionNameJpn?: 'asc' | 'desc';
  divisionNameEng?: 'asc' | 'desc';
  sbuCode?: 'asc' | 'desc';
  sbuNameJpn?: 'asc' | 'desc';
  sbuNameEng?: 'asc' | 'desc';
  departmentCode?: 'asc' | 'desc';
  departmentNameJpn?: 'asc' | 'desc';
  departmentNameEng?: 'asc' | 'desc';
  groupCode?: 'asc' | 'desc';
  groupNameJpn?: 'asc' | 'desc';
  groupNameEng?: 'asc' | 'desc';
  regionCode?: 'asc' | 'desc';
  regionNameJpn?: 'asc' | 'desc';
  regionNameEng?: 'asc' | 'desc';
  countryCode?: 'asc' | 'desc';
  countryNameJpn?: 'asc' | 'desc';
  countryNameEng?: 'asc' | 'desc';
  rank?: 'asc' | 'desc';
  applyStartDate?: 'asc' | 'desc';
  applyEndDate?: 'asc' | 'desc';
  createdBy?: 'asc' | 'desc';
  updatedBy?: 'asc' | 'desc';
};

export type SearchFilter = Omit<ComplementaryOrganizationEntity, 'id'>;

// 補完組織マスタ検索結果用のインターフェース
export type OmitField = 'isFuture' | 'isDeleted';
export interface ComplementaryOrganizationEntity extends Omit<OrganizationEntity, OmitField> {
  validTo: string | null;
  validFrom: string | null;
  createdBy: string | null;
  updatedBy: string | null;
}

export interface CountryEntity {
  countryCode: string | null;
  countryNameJpn: string | null;
  countryNameEng: string | null;
  regionCode: string | null;
}

export interface RegionEntity {
  regionCode: string | null;
  regionNameJpn: string | null;
  regionNameEng: string | null;
}

export interface RankEntity {
  rank: string | null;
}

export const useComplementaryOrganizationApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();
  const BASE_URL = `${apiGateway}/complementary-organization`;

  const CODE_FORMAT_ERROR = 'CODE_FORMAT_ERROR';
  const REGISTERED_ORGANIZATION_CODE = 'REGISTERED_ORGANIZATION_CODE';
  const NO_ORGANIZAITON_FOUND = 'NO_ORGANIZAITON_FOUND';
  const OUT_OF_BASE_DATE = 'OUT_OF_BASE_DATE';
  const OUT_OF_BASE_DATE_GROUP = 'OUT_OF_BASE_DATE_GROUP';
  const SUBORDINATE_ORGANIZATION_DATE_OUT_OF_RANGE = 'SUBORDINATE_ORGANIZATION_DATE_OUT_OF_RANGE';
  const REMOVE_SUBORDINATE_ORGANIZATION = 'REMOVE_SUBORDINATE_ORGANIZATION';

  /**
   * 補完組織情報の一覧を取得するためのAPI
   * @returns
   */
  const getComplementaryOrganizations = async (condition: SearchCondition) => {
    try {
      const token = await getBearerToken();
      const url = `${apiGateway}/complementary-organization`;
      const result = await axios.get<{ list: ComplementaryOrganizationEntity[]; count: number }>(url, {
        headers: { Authorization: token },
        params: { ...condition },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useComplementaryOrganizationApi.failedGetOrganizasionDataList'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * 組織種別、組織基準日に基づいた組織情報を取得する
   * isS500Onlyがtrueの場合はSコードがS500のもののみ取得する
   * isS500Onlyがfalseの場合はSコードがS500以外のもののみ取得する
   * @param orgLevelType
   * @param organizationBaseDate
   * @param isS500Only
   * @returns
   */
  const getOrgLevelType = async (orgLevelType: OrgLevelType, organizationBaseDate: Date, isS500Only: boolean) => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<OrganizationEntity[]>(`${BASE_URL}/org-level-type`, {
        headers: { Authorization: token },
        params: { orgLevelType, organizationBaseDate: format(organizationBaseDate, 'yyyy-MM-dd'), isS500Only },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('common.organization') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  const getComplementaryOrganizationDetail = async (id: string, keyword?: string) => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<ComplementaryOrganizationEntity>(`${BASE_URL}/${id}`, {
        headers: { Authorization: token },
        params: { id: id },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useComplementaryOrganizationApi.failedGetOrganizasionDataDetail'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * 補完組織の登録/更新のエラーハンドラ
   * @param error
   * @param name
   */
  const switchError = (error: AxiosError, name: string) => {
    switch (error.response?.data.message) {
      case CODE_FORMAT_ERROR: // 共通
        enqueueSnackbar(t('validateError.format.code'), { variant: 'error' });
        break;
      case REGISTERED_ORGANIZATION_CODE: // 共通
        enqueueSnackbar(t('apiExceptionMessage.useComplementaryOrganizationApi.registeredOrganizationCode'), { variant: 'error' });
        break;
      case OUT_OF_BASE_DATE: // 共通
        enqueueSnackbar(t('apiExceptionMessage.useComplementaryOrganizationApi.outOfBaseDate'), { variant: 'error' });
        break;
      case OUT_OF_BASE_DATE_GROUP: // 共通
        enqueueSnackbar(t('apiExceptionMessage.useComplementaryOrganizationApi.outOfBaseDateGroup'), { variant: 'error' });
        break;
      case SUBORDINATE_ORGANIZATION_DATE_OUT_OF_RANGE: // 更新処理のみ
        enqueueSnackbar(t('apiExceptionMessage.useComplementaryOrganizationApi.subordinateOrganizationDateOutOfRange'), { variant: 'error' });
        break;
      case NO_ORGANIZAITON_FOUND: // 更新処理のみ
        enqueueSnackbar(t('apiExceptionMessage.useComplementaryOrganizationApi.failedGetOrganizasionDataDetail'), { variant: 'error' });
        break;
      default:
        enqueueSnackbar(t(`apiExceptionMessage.common.${name}`, { target: t('common.complementaryOrganization') }), { variant: 'error' });
    }
  };

  /**
   * 補完組織の登録
   * @param data
   * @returns
   */
  const createComplementaryOrganization = async (data: ComplementaryOrganizationEntity) => {
    try {
      const token = await getBearerToken();
      await axios.post<ComplementaryOrganizationEntity>(BASE_URL, data, {
        headers: { Authorization: token },
        timeout: 60000,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        switchError(error, 'failedRegister');
      }
      throw error;
    }
  };

  /**
   * 更新
   * @param id
   * @param data
   * @returns
   */
  const updateComplementaryOrganization = async (id: string, data: ComplementaryOrganizationEntity) => {
    try {
      const token = await getBearerToken();
      await axios.put<ComplementaryOrganizationEntity>(`${BASE_URL}/${id}`, data, {
        headers: { Authorization: token },
        params: { id: id },
        timeout: 60000,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        switchError(error, 'failedUpdate');
      }
      throw error;
    }
  };

  const deleteComplementaryOrganization = async (id: string) => {
    try {
      const token = await getBearerToken();
      const result = await axios.delete<ComplementaryOrganizationEntity>(`${BASE_URL}/${id}`, {
        headers: { Authorization: token },
        params: { id: id },
        timeout: 60000,
      });
      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        switch (error.response?.data.message) {
          case REMOVE_SUBORDINATE_ORGANIZATION: // 削除補完組織の下位に補完組織が存在する場合
            enqueueSnackbar(t('apiExceptionMessage.useComplementaryOrganizationApi.failedDeleteOrganizasionSubordinate'), { variant: 'error' });
            break;
          default:
            enqueueSnackbar(t('apiExceptionMessage.useComplementaryOrganizationApi.failedDeleteOrganizasionData'), { variant: 'error' });
        }
        throw error;
      } else {
        throw error;
      }
    }
  };

  const getCountriesByRegionCode = async (regionCode?: string) => {
    try {
      const token = await getBearerToken();
      const result = await axios.get(`${BASE_URL}/countries/${regionCode}`, {
        headers: { Authorization: token },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('common.country') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  const getRegions = async () => {
    try {
      const token = await getBearerToken();
      const result = await axios.get(`${BASE_URL}/regions`, {
        headers: { Authorization: token },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('common.region') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  const getRanks = async (filter?: string) => {
    try {
      const token = await getBearerToken();
      const result = await axios.get(portalApi + `/master/rank`, {
        headers: { Authorization: token },
        timeout: 60000,
        params: {
          filter: filter || {
            where: {
              rank: {
                not: 'ZZ',
              },
            },
          },
        },
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useComplementaryOrganizationApi.failedGetRanks'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  return {
    getRanks,
    getRegions,
    getCountriesByRegionCode,
    getComplementaryOrganizations,
    getComplementaryOrganizationDetail,
    createComplementaryOrganization,
    updateComplementaryOrganization,
    deleteComplementaryOrganization,
    getOrgLevelType,
  };
};
