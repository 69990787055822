import { Box, Grid, MenuItem } from '@mui/material';
import { GridApi } from 'ag-grid-community';
import { format, subMonths } from 'date-fns';
import { useRef, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Label from 'src/components/Label';
import { StyledButton } from 'src/components/app-components/StyledButton';
import RHFDropdown from 'src/components/react-hook-form/RHFDropdown';
import { TOP_GLOBAL_COMPANY } from 'src/constants';
import {
  GridSetting,
  ListCondition,
  SearchListCondition,
  SelectCustomerModal,
} from 'src/features/general/enquete-answer/components/modal/select-trade-customer/components/SelectCustomerModal';
import { useGetSelectedRow } from 'src/features/general/enquete-answer/components/modal/select-trade-customer/hooks/useGetSelectedRow';
import { useTypeSeriesSurveyClickHandle } from 'src/features/general/enquete-answer/components/modal/select-trade-customer/hooks/useTypeSeriesSurveyClickHandle';
import { useAppSelector } from 'src/redux/store';
import { conversionUtcTypeDate } from 'src/utils/formatDateTime';
import { useSetCurrency } from '../hooks/useSetCurrency';

interface Props {
  methods: UseFormReturn<FieldValues, any>;
}

export const TabHeader: React.FC<Props> = (props) => {
  const { methods } = props;
  const { t } = useTranslation();
  const { currency, currencies, isUsePastData } = useAppSelector((state) => state.seriesAnswer);
  const { respondentRequestData } = useAppSelector((state) => state.enqueteAnswer);
  const { handleChangeSetCurrency } = useSetCurrency();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const gridApi = useRef<GridApi | null>(null);

  // new DateするとLocal Time Zoneに依存してしまうためUTCに変換
  const subMonthDate = subMonths(new Date(respondentRequestData?.targetDate ?? ''), 1);
  const utcSubMonthDate = conversionUtcTypeDate(new Date(subMonthDate.getUTCFullYear(), subMonthDate.getUTCMonth(), 15).toUTCString());

  // 主要22社のA1コード
  const topGlobalCustomer = Object.values(TOP_GLOBAL_COMPANY);
  const excludeCondition = {
    customerCode: [
      TOP_GLOBAL_COMPANY.TOYOTA_AUTO_BODY,
      TOP_GLOBAL_COMPANY.HINO_MOTORS,
      TOP_GLOBAL_COMPANY.DAIHATSU_MOTOR,
      TOP_GLOBAL_COMPANY.WOVEN_BY_TOYOTA,
      TOP_GLOBAL_COMPANY.TOYOTA_MOTOR_EAST_JAPAN,
      TOP_GLOBAL_COMPANY.TOYOTA_CENTRAL_LABS,
      TOP_GLOBAL_COMPANY.TOYOTA_MOTOR_KYUSHU,
    ],
    parentCustomerCode: [
      TOP_GLOBAL_COMPANY.TOYOTA_AUTO_BODY,
      TOP_GLOBAL_COMPANY.HINO_MOTORS,
      TOP_GLOBAL_COMPANY.DAIHATSU_MOTOR,
      TOP_GLOBAL_COMPANY.WOVEN_BY_TOYOTA,
      TOP_GLOBAL_COMPANY.TOYOTA_MOTOR_EAST_JAPAN,
      TOP_GLOBAL_COMPANY.TOYOTA_CENTRAL_LABS,
      TOP_GLOBAL_COMPANY.TOYOTA_MOTOR_KYUSHU,
    ],
    topGlobalCustomerCode: [TOP_GLOBAL_COMPANY.TOYOTA_MOTOR],
    excludeCustomer: [TOP_GLOBAL_COMPANY.TOYOTA_MOTOR],
  };

  // プルダウンの親取引先リストの条件
  const searchListCondition: SearchListCondition = {
    parentCustomerCondition: topGlobalCustomer,
  };

  // グリッドの一覧に表示する取引先の条件、除外条件
  const listCondition: ListCondition = {
    gridList: topGlobalCustomer,
    excludeCondition: excludeCondition,
    detailCondition: {
      entryDate: format(utcSubMonthDate, 'yyyy/MM/dd'),
    },
  };

  // グリッドの設定
  const gridSettingCondition: GridSetting = {
    gridApi,
  };

  // 選択された取引先を取得する関数
  const { getSelectedRowElement } = useGetSelectedRow({ gridApi });

  // 取引先追加ボタン押された際の挙動
  const { isAddCustomerLoading, seriesSurveyOnClick } = useTypeSeriesSurveyClickHandle({
    setIsOpen,
    getSelectedRowElement,
  });

  return (
    <>
      <Grid item xs={12} sx={{ display: 'flex', marginBottom: '10px' }}>
        <Grid item xs={4}>
          <Box sx={{ p: 1, height: '65px' }}>
            <StyledButton
              onClick={() => {
                setIsOpen(true);
              }}
              sx={{ marginTop: '10px' }}>
              {t('button.addCustomer')}
            </StyledButton>
          </Box>
          <Label variant="ghost" color="warning" sx={{ lineHeight: 'normal' }}>
            {t('enqueteAnswerInput.seriesSurvey.tradeVolume.message.EnterNumericValue')}
          </Label>
        </Grid>
        <Grid item xs={8} sx={{ textAlign: 'end' }}>
          <Grid>
            <RHFDropdown
              name={t('enqueteAnswerInput.seriesSurvey.dropdown.currency')}
              label={t('enqueteAnswerInput.seriesSurvey.dropdown.currency')}
              control={methods.control}
              handleOnChange={async (e) => await handleChangeSetCurrency(e, methods)}
              defaultValue={currency}
              disabled={isUsePastData}
              rules={{ required: { value: true, message: t('validateError.selectValue') } }}>
              {currencies.map((money) => (
                <MenuItem key={money.currencyCode} value={money.currencyCode}>
                  {money.currencyCode}
                </MenuItem>
              ))}
            </RHFDropdown>
          </Grid>
          <Grid>
            {/* 過去回答を使用している場合は非表示 */}
            {!isUsePastData && (
              <Label variant="ghost" color="warning" sx={{ lineHeight: 'normal' }}>
                {t('enqueteAnswerInput.seriesSurvey.tradeVolume.message.FinancialStatementReportingCurrency')}
              </Label>
            )}
          </Grid>
        </Grid>
      </Grid>
      <SelectCustomerModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        searchListCondition={searchListCondition}
        listCondition={listCondition}
        gridSettingCondition={gridSettingCondition}
        onClick={seriesSurveyOnClick}
        isAddCustomerLoading={isAddCustomerLoading}
      />
    </>
  );
};
