import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CustomerPerformance } from 'src/@types/overviewEnquete';
import { SeriesEnqueteOptionData } from 'src/@types/seriesEnquete';
import { COMPANY, TOP_GLOBAL_COMPANY } from 'src/constants';

const initOptionData: SeriesEnqueteOptionData = {
  customerManager: [
    {
      customerCode: TOP_GLOBAL_COMPANY.TOYOTA_MOTOR,
      customerNameJpn: COMPANY.TOYOTA_MOTOR.name,
      customerNameEng: COMPANY.TOYOTA_MOTOR.eng_name,
      manager: '',
      subManager: '',
    },
    {
      customerCode: TOP_GLOBAL_COMPANY.DENSO,
      customerNameJpn: COMPANY.DENSO.name,
      customerNameEng: COMPANY.DENSO.eng_name,
      manager: '',
      subManager: '',
    },
    {
      customerCode: TOP_GLOBAL_COMPANY.AISIN,
      customerNameJpn: COMPANY.AISIN.name,
      customerNameEng: COMPANY.AISIN.eng_name,
      manager: '',
      subManager: '',
    },
    {
      customerCode: TOP_GLOBAL_COMPANY.TOYOTA_INDUSTRIES,
      customerNameJpn: COMPANY.TOYOTA_INDUSTRIES.name,
      customerNameEng: COMPANY.TOYOTA_INDUSTRIES.eng_name,
      manager: '',
      subManager: '',
    },
    {
      customerCode: TOP_GLOBAL_COMPANY.TOYOTA_BOSHOKU,
      customerNameJpn: COMPANY.TOYOTA_BOSHOKU.name,
      customerNameEng: COMPANY.TOYOTA_BOSHOKU.eng_name,
      manager: '',
      subManager: '',
    },
    {
      customerCode: TOP_GLOBAL_COMPANY.TOYODA_GOSEI,
      customerNameJpn: COMPANY.TOYODA_GOSEI.name,
      customerNameEng: COMPANY.TOYODA_GOSEI.eng_name,
      manager: '',
      subManager: '',
    },
    {
      customerCode: TOP_GLOBAL_COMPANY.JTEKT_CORPORATION,
      customerNameJpn: COMPANY.JTEKT_CORPORATION.name,
      customerNameEng: COMPANY.JTEKT_CORPORATION.eng_name,
      manager: '',
      subManager: '',
    },
    {
      customerCode: TOP_GLOBAL_COMPANY.AICHI_STEEL,
      customerNameJpn: COMPANY.AICHI_STEEL.name,
      customerNameEng: COMPANY.AICHI_STEEL.eng_name,
      manager: '',
      subManager: '',
    },
    {
      customerCode: TOP_GLOBAL_COMPANY.TOYOTA_AUTO_BODY,
      customerNameJpn: COMPANY.TOYOTA_AUTO_BODY.name,
      customerNameEng: COMPANY.TOYOTA_AUTO_BODY.eng_name,
      manager: '',
      subManager: '',
    },
    {
      customerCode: TOP_GLOBAL_COMPANY.HINO_MOTORS,
      customerNameJpn: COMPANY.HINO_MOTORS.name,
      customerNameEng: COMPANY.HINO_MOTORS.eng_name,
      manager: '',
      subManager: '',
    },
    {
      customerCode: TOP_GLOBAL_COMPANY.DAIHATSU_MOTOR,
      customerNameJpn: COMPANY.DAIHATSU_MOTOR.name,
      customerNameEng: COMPANY.DAIHATSU_MOTOR.eng_name,
      manager: '',
      subManager: '',
    },
    {
      customerCode: TOP_GLOBAL_COMPANY.SUZUKI_MOTOR,
      customerNameJpn: COMPANY.SUZUKI_MOTOR.name,
      customerNameEng: COMPANY.SUZUKI_MOTOR.eng_name,
      manager: '',
      subManager: '',
    },
    {
      customerCode: TOP_GLOBAL_COMPANY.MAZDA_MOTOR,
      customerNameJpn: COMPANY.MAZDA_MOTOR.name,
      customerNameEng: COMPANY.MAZDA_MOTOR.eng_name,
      manager: '',
      subManager: '',
    },
    {
      customerCode: TOP_GLOBAL_COMPANY.SUBARU,
      customerNameJpn: COMPANY.SUBARU.name,
      customerNameEng: COMPANY.SUBARU.eng_name,
      manager: '',
      subManager: '',
    },
    {
      customerCode: TOP_GLOBAL_COMPANY.KOITO_MANUFACTURING,
      customerNameJpn: COMPANY.KOITO_MANUFACTURING.name,
      customerNameEng: COMPANY.KOITO_MANUFACTURING.eng_name,
      manager: '',
      subManager: '',
    },
    {
      customerCode: TOP_GLOBAL_COMPANY.YAZAKI,
      customerNameJpn: COMPANY.YAZAKI.name,
      customerNameEng: COMPANY.YAZAKI.eng_name,
      manager: '',
      subManager: '',
    },
    //系列別追加開発対応
    {
      customerCode: TOP_GLOBAL_COMPANY.TOYOTA_MOTOR_KYUSHU,
      customerNameJpn: COMPANY.TOYOTA_MOTOR_KYUSHU.name,
      customerNameEng: COMPANY.TOYOTA_MOTOR_KYUSHU.eng_name,
      manager: '',
      subManager: '',
    },
    {
      customerCode: TOP_GLOBAL_COMPANY.TOYOTA_MOTOR_EAST_JAPAN,
      customerNameJpn: COMPANY.TOYOTA_MOTOR_EAST_JAPAN.name,
      customerNameEng: COMPANY.TOYOTA_MOTOR_EAST_JAPAN.eng_name,
      manager: '',
      subManager: '',
    },
    {
      customerCode: TOP_GLOBAL_COMPANY.WOVEN_BY_TOYOTA,
      customerNameJpn: COMPANY.WOVEN_BY_TOYOTA.name,
      customerNameEng: COMPANY.WOVEN_BY_TOYOTA.eng_name,
      manager: '',
      subManager: '',
    },
    {
      customerCode: TOP_GLOBAL_COMPANY.TOYOTA_HOUSING_CORPORATION,
      customerNameJpn: COMPANY.TOYOTA_HOUSING_CORPORATION.name,
      customerNameEng: COMPANY.TOYOTA_HOUSING_CORPORATION.eng_name,
      manager: '',
      subManager: '',
    },
    {
      customerCode: TOP_GLOBAL_COMPANY.TOYOTA_CENTRAL_LABS,
      customerNameJpn: COMPANY.TOYOTA_CENTRAL_LABS.name,
      customerNameEng: COMPANY.TOYOTA_CENTRAL_LABS.eng_name,
      manager: '',
      subManager: '',
    },
    {
      customerCode: TOP_GLOBAL_COMPANY.TOYOTA_FUDOSAN,
      customerNameJpn: COMPANY.TOYOTA_FUDOSAN.name,
      customerNameEng: COMPANY.TOYOTA_FUDOSAN.eng_name,
      manager: '',
      subManager: '',
    },
  ],
};

export const enqueteOptionSlice = createSlice({
  name: 'enqueteOption',
  initialState: {
    // オプションタブの情報
    seriesEnqueteOptionData: initOptionData,
    overviewEnqueteOptionData: [] as CustomerPerformance[],
  },
  reducers: {
    setSeriesEnqueteOptionCustomer: (state, action) => {
      let newRow = action.payload;
      let newRowData = { ...state.seriesEnqueteOptionData };
      if (newRowData.customerManager) {
        let index = newRowData.customerManager.findIndex((element: any) => element.customerCode === newRow.customerCode);
        newRowData.customerManager.splice(index, 1, newRow);
      }
      state.seriesEnqueteOptionData = newRowData;
    },
    setSeriesEnqueteOptionData: (state, action: PayloadAction<SeriesEnqueteOptionData>) => {
      state.seriesEnqueteOptionData = action.payload;
    },
    setOverviewEnqueteOptionAgGridRaw: (state, action) => {
      let newRow = action.payload as CustomerPerformance;
      let newRowData = state.overviewEnqueteOptionData;
      if (newRowData) {
        let index = newRowData.findIndex((element: any) => element.fiscalYear === newRow.fiscalYear);
        newRowData.splice(index, 1, newRow);
      }
      state.overviewEnqueteOptionData = newRowData;
    },
    setOverviewEnqueteOptionAgGridData: (state, action: PayloadAction<CustomerPerformance[]>) => {
      state.overviewEnqueteOptionData = action.payload;
    },
    // オプションを初期化
    initEnqueteOptions: (state) => {
      state.seriesEnqueteOptionData = initOptionData;
    },
  },
});

export const {
  setSeriesEnqueteOptionCustomer,
  setSeriesEnqueteOptionData,
  setOverviewEnqueteOptionAgGridRaw,
  setOverviewEnqueteOptionAgGridData,
  initEnqueteOptions,
} = enqueteOptionSlice.actions;

export default enqueteOptionSlice.reducer;
